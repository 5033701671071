
import React from "react";
import { IonLoading } from "@ionic/react";

export const Loading: React.FC = () => {

    return (
        <IonLoading isOpen={true}
        message={'Please wait...'}
        />   
    )
}