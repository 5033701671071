import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupConfig } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "bootstrap/dist/css/bootstrap.min.css";
/* Theme variables */
import "./theme/variables.css";
import { Loading } from "./pages/Loading";
import { RateContextProvider } from "./Context/rate";
import Edit from "./pages/Edit";
import Cargo from "./pages/Cargo";

import { QueryClient, QueryClientProvider } from 'react-query'
 
const queryClient = new QueryClient()

const firebaseConfig = {
  apiKey: "AIzaSyBmTyHI4covEFDdtf-yFg4nvEfesK81oSo",
  authDomain: "yeheyremit-dbd1f.firebaseapp.com",
  databaseURL: "https://yeheyremit-dbd1f.firebaseio.com",
  projectId: "yeheyremit-dbd1f",
  storageBucket: "yeheyremit-dbd1f.appspot.com",
  messagingSenderId: "371326872487",
  appId: "1:371326872487:web:59c743a87853cfbf26a482",
};
const App: React.FC = () => {
  setupConfig({
    rippleEffect: false,
    mode: "ios",
  });

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <QueryClientProvider client={queryClient}>
        <SuspenseWithPerf fallback={<Loading />} traceId={"load-burrito-status"}>
      
          <RateContextProvider>
            <IonApp>
              <IonReactRouter>
                <IonRouterOutlet>
                  <Route path="/" component={Home} exact={true} />
                  <Route path="/edit" component={Edit} exact={true} />
                  <Route path="/cargo" component={Cargo} exact={true} />
                  <Route render={() => <Redirect to="/" />} exact={true} />
                </IonRouterOutlet>
              </IonReactRouter>
            </IonApp>
          </RateContextProvider>

        </SuspenseWithPerf>
      </QueryClientProvider>
    </FirebaseAppProvider>
  );
};

export default App;
