import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import { RateContext } from "../Context/rate";
import "./Home.css";

import { add, closeOutline } from "ionicons/icons";
import firebase from "firebase";
import { useFirestore } from "reactfire";
import { presentToast } from "../components/useToast";

const Edit: React.FC = () => {
  const { rate, slides, transaction } = React.useContext(RateContext);
  const [jpy_php, setjpy_php] = useState<string | number | null | undefined>(
    rate[0].JPY_PHP
  );
  const [usd_jpy, setusd_jpy] = useState<string | null | undefined>(
    rate[0].USD_JPY
  );
  const [usd_jpy_buy, setusd_jpy_buy] = useState<string | null | undefined>(
    rate[0].USD_JPY_Buy
  );
  const [usd_jpy_sell, setusd_jpy_sell] = useState<string | null | undefined>(
    rate[0].USD_JPY_Sell
  );
  const [usd_php, setusd_php] = useState<string | null | undefined>(
    rate[0].USD_PHP
  );
  const [decimal, setdecimal] = useState<string | null | undefined>(
    rate[0].decimal
  );
  const [url, seturl] = useState<string | null | undefined>();

  //Transaction State

  const [trans_today, settrans_today] = useState<string | null | undefined>(
    transaction[0].today
  );

  const [trans_monthDate, settrans_monthDate] = useState<
    string | null | undefined
  >(transaction[0].monthDate);

  const [trans_lastMonth, settrans_lastMonth] = useState<
    string | null | undefined
  >(transaction[0].lastMonth);

  const [trans_monthEnd, settrans_monthEnd] = useState<
    string | null | undefined
  >(transaction[0].monthEnd || 0);

  const [trans_targetDay, settrans_targetDay] = useState<
    string | null | undefined
  >(transaction[0].targetDay || 0);

  const firestore = useFirestore();

  const deleteAds = (id: string) => {
    firestore
      .collection("slides")
      .doc(id)
      .delete()
      .then(() => {
        presentToast("Deleted", "success", 2000);
      })
      .catch((e: any) => presentToast(JSON.stringify(e), "danger", 3000));
  };

  const addAds = () => {
    //const id = firestore.collection("slides").doc().id;

    firestore
      .collection("slides")
      .doc()
      .set({ url: url })
      .then(() => {
        presentToast("Added", "success", 2000);
        seturl("");
      })
      .catch((e: any) => presentToast(JSON.stringify(e), "danger", 3000));
  };

  const changeRate = () => {
    const rate = {
      JPY_PHP: Number(jpy_php),
      USD_JPY: Number(usd_jpy),
      USD_JPY_Buy: Number(usd_jpy_buy),
      USD_JPY_Sell: Number(usd_jpy_sell),
      USD_PHP: Number(usd_php),
      decimal: decimal,
      timestamp: firebase.firestore.Timestamp.now(),
    };

    firestore
      .collection("rates")
      .doc()
      .set(rate)
      .then(() => {
        presentToast("Rate Changed", "success", 4000);

        /*
        setjpy_php("");
        setusd_jpy("");
        setusd_jpy_buy("");
        setusd_jpy_sell("");
        setusd_php("");
        */
      })
      .catch((e: any) => presentToast(JSON.stringify(e), "danger", 3000));
  };

  const changeTrans = () => {
    const trans = {
      today: Number(trans_today),
      monthDate: Number(trans_monthDate),
      lastMonth: Number(trans_lastMonth),
      timestamp: firebase.firestore.Timestamp.now(),
      monthEnd: Number(trans_monthEnd),
      targetDay: Number(trans_targetDay),
    };

    firestore
      .collection("transaction")
      .doc()
      .set(trans)
      .then(() => {
        presentToast("Transaction Changed", "success", 4000);
        /*
        setjpy_php("");
        setusd_jpy("");
        setusd_jpy_buy("");
        setusd_jpy_sell("");
        setusd_php("");
        */
      })
      .catch((e: any) => presentToast(JSON.stringify(e), "danger", 3000));
  };

  const postRate = useCallback(() => {
    const rate = Number(jpy_php).toFixed(4);
    fetch(`https://api.yehey.jp/remit/rate/today?query=${rate}`, {
      method: "Post",
    })
      .then(() => {
        presentToast("Bot will post the New Rate", "success", 8000);
      })
      .catch((e: any) => presentToast(JSON.stringify(e), "danger", 3000));
  }, [jpy_php]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="content center">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonItemDivider>FX Rate</IonItemDivider>
                  <IonRow>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>
                          JPY/PHP - {rate[0].JPY_PHP}
                        </IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={jpy_php}
                          onIonChange={(e) => setjpy_php(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>
                          USD/JPY - {rate[0].USD_JPY}
                        </IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={usd_jpy}
                          onIonChange={(e) => setusd_jpy(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>
                          USD/JPY (Buy) - {rate[0].USD_JPY_Buy}
                        </IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={usd_jpy_buy}
                          onIonChange={(e) => setusd_jpy_buy(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>
                          USD/JPY (Sell) - {rate[0].USD_JPY_Sell}
                        </IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={usd_jpy_sell}
                          onIonChange={(e) => setusd_jpy_sell(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>
                          USD/PHP - {rate[0].USD_PHP}
                        </IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={usd_php}
                          onIonChange={(e) => setusd_php(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>Decimal</IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={decimal}
                          onIonChange={(e) => setdecimal(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-end ion-padding-end ion-padding-bottom">
                      <IonButton onClick={() => postRate()} color="success">
                        Post Rate
                      </IonButton>
                    </IonCol>
                    <IonCol className="ion-text-end ion-padding-end ion-padding-bottom">
                      <IonButton onClick={() => changeRate()}>Change</IonButton>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonItemDivider>No. of Transaction</IonItemDivider>
                  <IonRow>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>Today</IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={trans_today}
                          onIonChange={(e) => settrans_today(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>Month to date</IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={trans_monthDate}
                          onIonChange={(e) =>
                            settrans_monthDate(e.detail.value)
                          }
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>Month-end Target</IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={trans_monthEnd}
                          onIonChange={(e) => settrans_monthEnd(e.detail.value)}
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>Target per day</IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={trans_targetDay}
                          onIonChange={(e) =>
                            settrans_targetDay(e.detail.value)
                          }
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                    <IonCol>
                      <IonCard>
                        <IonItemDivider>Last month</IonItemDivider>
                        <IonInput
                          className="ion-padding-start"
                          type="number"
                          value={trans_lastMonth}
                          onIonChange={(e) =>
                            settrans_lastMonth(e.detail.value)
                          }
                        ></IonInput>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-end ion-padding-end ion-padding-bottom">
                      <IonButton onClick={() => changeTrans()}>
                        Change
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
            {false && (
              <IonRow>
                <IonCol>
                  <IonCard>
                    <IonItemDivider>Ads</IonItemDivider>
                    <IonRow>
                      {slides.map((slide: any) => {
                        return (
                          <IonCol key={slide.id}>
                            <IonCard>
                              <IonItemDivider color="white">
                                <IonIcon
                                  icon={closeOutline}
                                  slot="end"
                                  color="danger"
                                  onClick={() => deleteAds(slide.id)}
                                ></IonIcon>
                              </IonItemDivider>
                              <IonImg
                                className="slide-img"
                                src={slide.url}
                              ></IonImg>
                            </IonCard>
                          </IonCol>
                        );
                      })}
                      <IonCol></IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonCard>
                          <IonItemDivider>Add</IonItemDivider>
                          <IonItem lines="none">
                            <IonInput
                              className="ion-padding-start"
                              placeholder="Url"
                              type="url"
                              onIonChange={(e) => seturl(e.detail.value)}
                            ></IonInput>
                            <IonIcon
                              icon={add}
                              slot="end"
                              onClick={() => addAds()}
                            />
                          </IonItem>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Edit;
