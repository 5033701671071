import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React from "react";
import "./ExploreContainer.css";

interface ContainerProps {}

const ExploreContainer: React.FC<ContainerProps> = () => {
  return (
    <div style={{ background: "black" }}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonImg src="\assets\cargo-price.png"></IonImg>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ExploreContainer;
