import {
  IonBadge,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import CargoContainer from "../components/CargoContainer";
import "./Home.css";
import useKeypress from "./keypress";

const Cargo: React.FC = () => {
  const history = useHistory();
  useKeypress(["ArrowLeft"], () => {
    history.push("/");
  });

  return (
    <IonPage style={{ background: "black" }}>
      <IonHeader style={{ background: "black", borderColor: "whiite" }}>
        <IonToolbar
          className="remit-toolbar"
          color="darl"
          style={{ borderColor: "whiite" }}
        >
          <IonTitle className="ion-padding-bottom ion-padding-top">
            <IonImg className="logo" src="\assets\2.png" alt=""></IonImg>
          </IonTitle>

          <IonTitle className="ion-padding-top" slot="end" color="light">
            <IonBadge
              color="light"
              className="ion-padding-start ion-padding-end"
            >
              <h2>Call Us: 03-4550-1409</h2>
            </IonBadge>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="dark">
        <CargoContainer />
      </IonContent>
    </IonPage>
  );
};

export default Cargo;
