export async function presentToast(
  message: string,
  color: string | undefined,
  duration: number | undefined
) {
  const toast = document.createElement("ion-toast");
  toast.message = message;
  toast.duration = duration || 2000;
  toast.color = color;

  document.body.appendChild(toast);
  return toast.present();
}
