import {
  IonCard,
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/react";
import React from "react";
import { RateContext } from "../Context/rate";
import moment from "moment";
import "./ExploreContainer.css";
import { Transaction } from "./transaction";

interface ContainerProps {}

const ExploreContainer: React.FC<ContainerProps> = () => {
  const { rate } = React.useContext(RateContext);

  return (
    <div>
      <div
        data-aos-easing="ease"
        data-aos-duration="600"
        data-aos-delay="0"
        data-gr-c-s-loaded="true"
      >
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <main>
              <div className="header-green page-header-dark">
                <div className="page-header-content pt-10">
                  <div className="align-items-center">
                    <div
                      className="aos-init aos-animate text-center"
                      data-aos="fade-up"
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <div>
                              <h1
                                id="rate"
                                className="page-header-title rate-h1"
                              >
                                {rate[0].JPY_PHP &&
                                  rate[0].JPY_PHP + rate[0].decimal}{" "}
                                JPY
                              </h1>
                            </div>
                            <h4 id="date" className="page-header-text">
                              As of{" "}
                              {moment
                                .unix(rate[0].timestamp.seconds)
                                .format("LLL")}
                            </h4>
                            <h4 className="page-header-text">
                              Japanese Yen to Philippine Peso Conversion
                            </h4>
                          </IonCol>
                          <IonCol size="3">
                            <div className="padding-list">
                              <IonItem lines="none" color="remit">
                                <IonText slot="end">
                                  <h3>FX Rate</h3>
                                </IonText>
                              </IonItem>

                              <IonCard className="ion-no-margin">
                                <IonRow className="text-center p-0">
                                  <IonCol className="p-0">
                                    <IonItem
                                      lines="none"
                                      className="totalItem"
                                      color="dark"
                                    >
                                      <IonText>USD/JPY</IonText>

                                      <IonText slot="end">
                                        {rate[0].USD_JPY}
                                      </IonText>
                                    </IonItem>
                                  </IonCol>
                                </IonRow>
                                <IonRow className="text-center">
                                  <IonCol className="p-0">
                                    <IonItem
                                      lines="none"
                                      className="totalItem"
                                      color="light"
                                    >
                                      <IonText>USD/JPY (Buy)</IonText>

                                      <IonText slot="end">
                                        {rate[0].USD_JPY_Buy}
                                      </IonText>
                                    </IonItem>
                                  </IonCol>
                                </IonRow>
                                <IonRow className="text-center p-0">
                                  <IonCol className="p-0">
                                    <IonItem
                                      lines="none"
                                      className="totalItem"
                                      color="dark"
                                    >
                                      <IonText>USD/JPY (Sell)</IonText>

                                      <IonText slot="end">
                                        {rate[0].USD_JPY_Sell}
                                      </IonText>
                                    </IonItem>
                                  </IonCol>
                                </IonRow>
                                <IonRow className="text-center">
                                  <IonCol className="p-0">
                                    <IonItem
                                      lines="none"
                                      className="totalItem"
                                      color="light"
                                    >
                                      <IonText>USD/PHP</IonText>

                                      <IonText slot="end">
                                        {rate[0].USD_PHP}
                                      </IonText>
                                    </IonItem>
                                  </IonCol>
                                </IonRow>
                              </IonCard>
                              <h6 className="page-header-text ion-padding-top">
                                Rates are subject to change w/out proir notice,
                                depending on FX market conditions.
                              </h6>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  </div>
                </div>
                <div className="svg-border-rounded text-white">
                  <svg
                    className="svgcurve"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144.54 17.34"
                    preserveAspectRatio="none"
                    fill="currentColor"
                  >
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                  </svg>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <div className="ion-padding-top"></div>
      <Transaction />
    </div>
  );
};

export interface Slides {
  url: string | undefined;
  id: string | undefined;
}

const Slides = () => {
  const slideOpts = {
    initialSlide: 1,
    loop: true,
    speed: 400,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
  };
  const { slides } = React.useContext(RateContext);

  return (
    <IonSlides pager={false} options={slideOpts}>
      {slides &&
        slides.map((slide: Slides) => {
          return (
            <IonSlide>
              <IonImg className="slide-img" src={slide.url}></IonImg>
            </IonSlide>
          );
        })}
    </IonSlides>
  );
};

export default ExploreContainer;
