import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import "./Home.css";
import useKeypress from "./keypress";

const Home: React.FC = () => {
  const history = useHistory();
  useKeypress(["ArrowRight"], () => {
    history.push("/cargo");
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="remit-toolbar">
          <IonTitle className="ion-padding-bottom ion-padding-top">
            <IonImg
              className="logo"
              src="https://www.yeheyremit.jp/wp-content/uploads/2019/12/Yehey-Remit-logo-small-02-1.png"
            ></IonImg>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <ExploreContainer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
