import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import { RateContext } from "../Context/rate";
import moment from "moment";
import momentb from "moment-business-days";
import { useQuery } from "react-query";

interface rateInterface {
  _id: string;
  type: string;
  rate: string;
  name: string;
  timestamp: number;
}

export const Transaction: React.FC = () => {
  const { transaction, rate } = React.useContext(RateContext);

  momentb.updateLocale("jp", {
    workingWeekdays: [1, 2, 3, 4, 5, 6],
  });

  const workingd = momentb()
    .monthBusinessDays()
    .filter((a, b) => a > moment());

  const {
    isLoading,
    error,
    data = [],
    dataUpdatedAt,
  } = useQuery<rateInterface[]>(
    "repoData",
    () => fetch("https://api.yehey.jp/remit/rate").then((res) => res.json()),
    {
      refetchInterval: 300000,
      refetchIntervalInBackground: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );
  const { data: botPushed } = useQuery<{
    timestamp?: number;
  }>(
    "repoDatas",
    () =>
      fetch("https://api.yehey.jp/remit/rate/pushed").then((res) => res.json()),
    {
      refetchInterval: 300000,
      refetchIntervalInBackground: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );

  return (
    <>
      <div className="last-update">
        <IonBadge
          color="dark"
          style={{
            padding: "10px 10px 10px 10px",
          }}
        >
          fetched{" "}
          {botPushed?.timestamp
            ? moment.unix(botPushed.timestamp).fromNow()
            : "unknown"}
        </IonBadge>
      </div>
      <div>
        <br></br>
      </div>
      <IonRow className="ion-text-center">
        {!isLoading &&
          data
            .filter((i) => i.name !== "iRemit")
            .map((i, index) => {
              let color = "";
              if (rate[0].JPY_PHP === parseFloat(i.rate)) {
                color = "warning";
              } else if (rate[0].JPY_PHP < parseFloat(i.rate)) {
                color = "danger";
              }

              return (
                <IonCol key={index}>
                  <IonCard color={color}>
                    <div
                      style={{
                        padding: "2px",
                      }}
                    >
                      <div>{i.name}</div>
                      <h6 style={{ marginBottom: 0 }}>{i.rate}</h6>
                      <small style={{ fontSize: 8 }}>
                        {moment
                          .unix(i.timestamp)
                          .fromNow()
                          .replace("minutes", "min")}
                      </small>
                    </div>
                  </IonCard>
                </IonCol>
              );
            })}
      </IonRow>

      <IonRow className="ion-text-center">
        <IonCol>
          <h6>
            Today - (
            {moment.unix(transaction[0].timestamp.seconds).format("MMM DD")})
          </h6>
        </IonCol>
        <IonCol>
          <h6>Month to date</h6>
        </IonCol>
        <IonCol>
          <h6>Month-end Target</h6>
        </IonCol>
        <IonCol>
          <h6>Target per day</h6>
        </IonCol>
        <IonCol>
          <h6>Remaining working days</h6>
        </IonCol>
        <IonCol>
          <h6>Last Month</h6>
        </IonCol>
      </IonRow>
      <IonRow className="ion-text-center">
        <IonCol>
          <h4>{transaction && transaction[0].today}</h4>
        </IonCol>
        <IonCol>
          <h4>{transaction && transaction[0].monthDate}</h4>
        </IonCol>
        <IonCol>
          <h4>{transaction && transaction[0].monthEnd | 0}</h4>
        </IonCol>
        <IonCol>
          <h4>{transaction && transaction[0].targetDay | 0}</h4>
        </IonCol>
        <IonCol>
          <h4>{workingd.length}</h4>
        </IonCol>
        <IonCol>
          <h4>{transaction && transaction[0].lastMonth}</h4>
        </IonCol>
      </IonRow>
    </>
  );
};

const sampleRate = [
  {
    _id: "61e7dcda4ccef423b3af234d",
    type: "Smiles",
    rate: "0.4475",
    timestamp: 1642585402,
  },
  {
    _id: "61e7dd504ccef423b3af244e",
    type: "Digitel",
    rate: "0.4465",
    timestamp: 1642585424,
  },
  {
    _id: "61e7dd654ccef423b3af2468",
    type: "WesternUnion",
    rate: "0.4463",
    timestamp: 1642585445,
  },
  {
    _id: "61e7dd6d4ccef423b3af247f",
    type: "SBI",
    rate: "0.4476",
    timestamp: 1642585453,
  },
  {
    _id: "61e7dd754ccef423b3af249b",
    type: "JRF",
    rate: "0.4461",
    timestamp: 1642585461,
  },
  {
    _id: "61e7dd7e4ccef423b3af24cd",
    type: "SevenBank",
    rate: "0.4406",
    timestamp: 1642585470,
  },
  {
    _id: "61e7dd864ccef423b3af24d8",
    type: "DCOM",
    rate: "0.4470",
    timestamp: 1642585478,
  },
  {
    _id: "61e7dd8d4ccef423b3af24eb",
    type: "iRemit",
    rate: "0.4470",
    timestamp: 1642585485,
  },
];
