import React, { createContext } from "react";

import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const RateContext = createContext({} as any);

export const RateContextProvider: React.FC = ({ children }) => {
  const firestore = useFirestore();

  const [state, setstate] = React.useState(false);

  const latestRate = firestore
    .collection("rates")
    .orderBy("timestamp", "asc")
    .limitToLast(1);

  const latestTrans = firestore
    .collection("transaction")
    .orderBy("timestamp", "asc")
    .limitToLast(1);

  const slideRef = firestore.collection("slides");

  const rate = useFirestoreCollectionData(latestRate);
  const transaction = useFirestoreCollectionData(latestTrans);
  const slides = useFirestoreCollectionData(slideRef, { idField: "id" });

  return (
    <RateContext.Provider
      value={{
        rate,
        transaction,
        slides,
        state,
        setstate,
      }}
    >
      {children}
    </RateContext.Provider>
  );
};
